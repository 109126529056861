import "./App.css";
import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import loading from "./Vendors/Images/loading.gif";
const Routing = React.lazy(() => import("./components/Routing/Routing.js"));
function App() {
  return (
    <div>
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={loading} alt="loading" />
          </div>
        }
      >
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
